// @flow
import React from 'react'
import {spring, Motion} from 'react-motion'
import isMobile from 'is-mobile'
import {useSelector} from 'react-redux'

import withDefault from '../../common/withDefault'
import type {PageSize} from '../../common/types'
import {backgroundImageDimensions} from '../../common/constants'

import type {State as StateType} from '../../stateType'

import Spinner from '../Spinner'

import imageUrl from '../../common/imageUrl'

import style from './Background.less'

type Props = {
  image?: ?string,
  video?: ?string,
  className?: string,
  dim?: number,
  dimColor?: string,
  muted?: boolean,
  playing?: boolean,
  volume?: number,
  autoplay?: boolean,
  attachment?: string,
  altText?: ?string,
  videoEndedListener?: Function,
  videoProgressListener?: Function,
}

export default (props: Props) => {
  const pageSize: PageSize = useSelector((state: StateType) => state.page.pageSize)
  const {
    image,
    video,

    dim,
    dimColor,

    muted,
    playing,
    volume,
    autoplay,
    attachment,
    className,
    altText,

    videoEndedListener,
    videoProgressListener,
  } = props

  if (!image && !video) return null

  const shouldShowVideo = (video && !isMobile()) || (video && isMobile() && /iPad|iPhone/.test(navigator.platform))

  return (
    <div className={[style.Background, className].join(' ')}>
      {shouldShowVideo ?
        <VideoBackground
          key={video}
          video={video}
          image={image}
          muted={withDefault(muted, true)}
          playing={withDefault(playing, false)}
          volume={withDefault(volume, 1)}
          autoplay={withDefault(autoplay, false)}
          endedListener={videoEndedListener}
          progressListener={videoProgressListener}
        /> :
        <ImageBackground
          image={image}
          attachment={attachment}
          className={style.ImageBackground}
          pageSize={pageSize}
          altText={altText}
        />
      }

      {dim ? <div className={style.Dim} style={{opacity: dim, background: dimColor || '#000'}} /> : null}
    </div>
  )
}

type Video = {
  autoplay: boolean,
  controls: boolean,
  loop: boolean,
  volume: number,
  playsinline: boolean,
  muted: boolean,
  play: Function,
  pause: Function,
  addEventListener: Function,
  removeEventListener: Function,
}

class VideoBackground extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loaded: false,
    }
  }

  video: Video

  finishLoading = () => {
    this.setState({loaded: true})
  }

  componentDidUpdate(previousProps) {
    const {playing, volume, muted} = this.props
    if (playing !== previousProps.playing) {
      playing ? this.video.play() : this.video.pause()
    }
    if (volume !== previousProps.volume) {
      this.video.volume = volume
    }
    if (muted !== previousProps.muted) {
      this.video.muted = muted
    }
  }

  videoSetup = videoElement => {
    const {autoplay, playing, volume, progressListener} = this.props
    this.video = videoElement
    if (!this.video) return
    this.video.autoplay = autoplay
    this.video.controls = false
    this.video.loop = true
    this.video.volume = volume

    if (playing) this.video.play()

    if (progressListener) {
      this.video.addEventListener('progress', progressListener)
    }

    this.video.addEventListener('ended', this.triggerEndedListener)
    this.video.addEventListener('loadeddata', this.finishLoading)
  }

  triggerEndedListener = () => {
    if (this.props.endedListener) this.props.endedListener()
    if (this.video.paused) this.video.play()
  }

  componentWillUnmount() {
    this.video.removeEventListener('loadeddata', this.finishLoading)
    this.video.removeEventListener('ended', this.triggerEndedListener)
    this.video.removeEventListener('progress', this.props.progressListener)
  }

  render() {
    const {video, muted} = this.props

    return (
      <Motion style={{opacity: spring(this.state.loaded ? 1 : 0)}}>
        {animated => (
          <div className={style.VideoBackground}>
            <video ref={this.videoSetup} className={style.video} muted={muted} style={animated} playsInline>
              <source src={video} type='video/mp4' />
            </video>

            <Spinner loading={!this.state.loaded} className={style.Spinner} />
          </div>
        )}
      </Motion>
    )
  }
}

class ImageBackground extends React.Component {
  render() {
    const {image, className, pageSize, altText} = this.props
    if (!image) return null

    const width = backgroundImageDimensions[pageSize].width
    const height = backgroundImageDimensions[pageSize].height
    const url = imageUrl(image, width, height)

    return (
      <div
        className={className}
        style={{
          backgroundImage: image ? `url(${url})` : null,
        }}
        title={altText}
        aria-label={altText}
      />
    )
  }
}

// @flow
import React from 'react'
import {useQuery} from '@apollo/client'

import artistHoldingIcon from './artistHoldingIcon.png'

import type {ArtistType, MatchType} from '../../common/types'
import {artistSocialLinks} from '../Socials/links'
import {itemize} from '../../common/itemize'
import {genericItemTypes, uiLocations} from '../../common/constants'
import imageUrl from '../../common/imageUrl'

import Loading from '../Loading'
import Page from '../Page'
import Share from '../Share'
import SocialIcons from '../Socials/SocialIcons'
import Card from '../Card'
import PlayerInitialiser from '../PlayerInitialiser'
import NotFound from '../NotFound'
import Link from '../Link'

import GET_ARTIST from './Query.js'

import styles from './Artist.less'

export default ({match}: {match: MatchType}) => {
  const {data, loading} = useQuery(GET_ARTIST, {
    variables: {
      slug: match.params.slug,
    },
  })

  if (loading) return <Loading />
  if (!data) return null

  const {artist_by_slug: artist}: {artist_by_slug: ArtistType} = data

  if (!artist || artist.hide_artist) return <NotFound />

  return (
    <Page
      title={artist.name}
      contentColumn={true}
      description={artist.description ? artist.description.slice(200) : ''}
    >
      <div className={styles.BackgroundWrapper} />
      <ArtistInfo artist={artist} />
      <RecordingsAndShows artist={artist} match={match} />
    </Page>
  )
}

const ArtistInfo = ({artist}: {artist: ArtistType}) =>
  <div className={styles.InfoWrapper}>
    <img
      className={styles.ArtistImage}
      src={imageUrl(artist.image, 300, 300) || artistHoldingIcon}
      alt={`${artist.name}-profile-image`}
    />
    <h4>ARTIST</h4>
    <h2>{artist.name}</h2>

    {artist.city && artist.city.slug &&
      <Link internalLink={`/city/${artist.city.slug}`} className={styles.CityLink}>
        {artist.city.name}
      </Link>
    }

    {!artist.city && artist.country &&
      <span className={styles.CountryText}>
        {artist.country}
      </span>
    }

    {artist.socials &&
      <SocialIcons links={artistSocialLinks(artist.socials)} className={styles.SocialIcons} />
    }

    <Share />
  </div>

const RecordingsAndShows = ({artist, match}: {artist: any, match: MatchType}) => {
  const {
    recordings,
    shows,
  } = artist

  const hasRecordings: boolean = recordings && recordings.length > 0
  const filteredShows = shows ? shows.filter(show => !show.hide_artists) : []
  const hasShows: boolean = filteredShows.length > 0

  if (!hasRecordings && !hasShows) return null

  return (
    <div className={styles.RecordingsAndShowsWrapper}>
      {hasRecordings &&
        <div className={styles.Recordings}>
          <div className={styles.PlayerInitialiserWrapper}>
            <h3>WATCH</h3>
            <PlayerInitialiser
              queue={recordings}
              location={match.path}
            />
          </div>
          <CardColumn
            items={recordings}
            itemType={genericItemTypes.recording}
          />
        </div>
      }

      {hasShows &&
        <div className={styles.Shows}>
          <h3>EVENTS</h3>
          <CardColumn
            items={shows}
            itemType={genericItemTypes.show}
          />
        </div>
      }
    </div>
  )
}

const CardColumn = ({items, itemType}) =>
  items
    .map((item, index) =>
      <Card item={itemize(item, itemType)} key={index} horizontal uiLocation={uiLocations.artistPageCard} />,
    )

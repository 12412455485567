// @flow
import React from 'react'
import {useSelector} from 'react-redux'
import {useQuery} from '@apollo/client'

import type {State} from '../../stateType'
import type {BrandType, ShopifyCollectionType, SponsorType, PageSize} from '../../common/types'
import {shopifyCollectionIds, genericItemTypes} from '../../common/constants'
import {itemizeProducts} from '../../common/itemize'
import {brandIds} from '../../common/brands'

import Slider from '../Slider'
import Query from '../Query'

import GET_SHOP_COLLECTION from './Query'
import SHOP_SETTINGS from '../Shop/SettingsQuery'

export default (props: {sponsor?: ?SponsorType, collectionId?: ?string, title?: ?string}) => {
  const {pageSize, brand, shopLocale} = useSelector(({page, brand, shop}: State) => ({
    pageSize: page.pageSize,
    brand,
    shopLocale: shop.shopLocale,
  }))

  return (
    <Query
      Component={ShopSlider}
      query={GET_SHOP_COLLECTION}
      context={{clientName: 'shopify'}}
      variables={{country: shopLocale, collectionId: props.collectionId || shopifyCollectionIds.all}}
      fetchPolicy='cache-first'
      props={{
        pageSize,
        brand,
        sponsor: props.sponsor,
        title: props.title,
      }}
    />
  )
}

const ShopSlider = (props: {
  brand: BrandType,
  collection: ShopifyCollectionType,
  loading: boolean,
  pageSize: PageSize,
  slug?: string,
  sponsor?: SponsorType,
  title?: string,
}) => {
  const {data} = useQuery(SHOP_SETTINGS)

  const {brand, loading, slug, sponsor} = props
  const collection = props.collection

  const isFourThree = brand && brand.id === brandIds.fourthree
  if (isFourThree) return null

  if (!loading && !collection) return null

  const shopOffline = data && data.web_settings && data.web_settings.shop_offline
  if (shopOffline) return null

  if (sponsor) return null

  const productFilter = product =>
    !product ||
    // filter out 'Hidden' and 'HideFromNavigationCollections' products
    !product.node.tags.includes('Hidden') ||
    !product.node.tags.includes('HideFromNavigationCollections') ||
    // filter out the current product
    !slug === product.node.handle

  const products = collection ?
    itemizeProducts(
      collection.products.edges.filter(productFilter),
      genericItemTypes.product
    ) :
    [false, false, false, false] // 4x empty loading cards

  const collectionTitle = collection && collection.title
  // use the collection title unless it's 'All', replace 'All' with 'Shop'
  const defaultTitle = collectionTitle === 'All' ? 'Shop' : collectionTitle
  // hardcoded title overrides default
  const title = props.title || defaultTitle

  return <Slider internalLink={'/shop'} items={products} title={title} />
}

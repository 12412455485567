import {gql} from '@apollo/client'

export default gql`
  query NavigationQuery {
    boiler_room_footer: navigation_by_slug(slug: "boiler-room-footer") {
      navigation_elements {
        name
        link
      }
    }
    fourthree_footer: navigation_by_slug(slug: "fourthree-footer") {
      navigation_elements {
        name
        link
      }
    }
    global_footer: navigation_by_slug(slug: "global-footer") {
      navigation_elements {
        name
        link
      }
    }
    projects_footer: navigation_by_slug(slug: "projects-footer") {
      navigation_elements {
        name
        link
      }
    }
  }
`

// @flow
import React from 'react'
import marked from 'marked'
import moment from 'moment'

import FourThreeInfo from '../FourThreeInfo'
import Link from '../Link'
import Logo from '../Logo'
import Sponsor from '../Sponsor'
import GenreButton from '../GenreButton'
import Share from '../Share'
import BrandedComponent from '../BrandedComponent'
import BrandedStyleWrapper from '../BrandedStyleWrapper'
import FavouriteToggle from '../FavouriteToggle'
import Stripe from '../Stripe'
import ToggleAudioVideo from '../ToggleAudioVideo'
import PlayerInitialiser from '../PlayerInitialiser'
import ReleaseTimeInfo from '../ReleaseTimeInfo'

import paths from '../../common/paths'
import {genericItemTypes, mediaFormats} from '../../common/constants'
import {itemize} from '../../common/itemize'
import type {RecordingType, LocationType} from '../../common/types'
import {durationToMin} from '../../common/durationToMin'
import {getChartPositionIndicatorText} from '../../common/chartHelpers'

import soundcloudLogo from './soundcloudLogo.svg'
import styles from './RecordingInfo.less'
import UpNextButton from '../UpNextButton'

type Props = {
  recording: RecordingType,
  location: LocationType,
}

const RecordingInfo = (props: Props) => {
  // $FlowFixMe
  const [checkoutOpen, setCheckoutOpen] = React.useState(false)
  const {recording, location} = props
  const {description, genres, series, sponsor, title} = recording

  const firstSeries = series && series[0]
  const seriesLogo = firstSeries && firstSeries.logo_image

  const metaData = [
    ...recording.formats.map(format => format.title),
    `${durationToMin(recording.duration)} min`,
    recording.film_release_date ? moment(recording.film_release_date).year() : null,
  ]

  // Dirty hack to make True Music Fund always appear on one line
  const parsedTitle = title.replace('True Music Fund:', 'True&nbsp;Music&nbsp;Fund:')
  const firstDigitalProduct = recording && recording.digital_products[0]

  const isAudioPage = location.pathname && location.pathname.includes('/audio/')

  if (isAudioPage) {
    return (
      <AudioRecordingInfo
        recording={recording}
        location={location}
        title={parsedTitle}
      />
    )
  }

  return (
    <BrandedStyleWrapper styles={styles} classNameKey={'Wrapper'}>
      <BrandedComponent
        brandContents={isFourThree => (
          isFourThree ?
            <FourThreeInfo
              title={title}
              description={description}
              director={recording && recording.director}
              metaData={metaData}
              sponsor={sponsor}
            />
            :
            <div>
              {seriesLogo &&
                <Link internalLink={paths.collection(firstSeries)}>
                  <Logo src={seriesLogo} />
                </Link>
              }

              {recording.logo_image ?
                <Logo src={recording.logo_image} large={true} />
                :
                <h2 className={styles.Title} dangerouslySetInnerHTML={{__html: parsedTitle}} />
              }

              <RecordingData recording={recording} />

              <Controls recording={recording} location={location} />

              <div className={styles.Description} dangerouslySetInnerHTML={{__html: marked(description)}} />

              <Sponsor sponsor={sponsor} />

              <div className={styles.Genres}>
                {genres && genres.map((genre, index) => (
                  <GenreButton
                    key={genre.id}
                    genre={genre.title}
                    slug={genre.slug}
                  />
                ))}
              </div>
            </div>
        )}
      />

      {checkoutOpen && <Stripe digitalProduct={firstDigitalProduct} closeCheckout={() => setCheckoutOpen(false)} />}
    </BrandedStyleWrapper>
  )
}

const AudioRecordingInfo = ({recording, location, title}) => {
  const {description, genres, sponsor} = recording

  return (
    <div className={styles.Wrapper}>
      <div className={styles.AudioHeaderWrapper}>
        <div className={styles.PlayButtonAndHeaderWrapper}>
          <PlayerInitialiser
            queue={[recording]}
            location={location}
            formatOverride={mediaFormats.audio}
            isAudioPage
          />
          <h2 className={styles.Title} dangerouslySetInnerHTML={{__html: title}} />
        </div>
        <a
          href={recording.soundcloud_track_permalink_url}
          target='_blank'
          className={styles.SoundCloudLogo}
        >
          <img src={soundcloudLogo} />
        </a>
      </div>

      <UpNextButton location={location} />

      <RecordingData recording={recording} />

      <Controls recording={recording} location={location} />

      <div className={styles.Description} dangerouslySetInnerHTML={{__html: marked(description)}} />

      <Sponsor sponsor={sponsor} />

      <div className={styles.Genres}>
        {genres && genres.map((genre, index) => (
          <GenreButton
            key={genre.id}
            genre={genre.title}
            slug={genre.slug}
          />
        ))}
      </div>
    </div>
  )
}

// Similar component to BroadcastData yet different enough to warrant separate code.
const RecordingData = ({recording}) => {
  const chartPositionIndicator = getChartPositionIndicatorText(recording)

  const city = recording.city || recording.show && recording.show.city

  return (
    <div className={styles.RecordingDataWrapper}>
      <ReleaseTimeInfo recording={recording} />

      {city &&
        <Link className={styles.CityLink} internalLink={`/city/${city.slug}`}>
          {city.name}
        </Link>
      }

      {chartPositionIndicator &&
        <Link className={styles.ChartPositionIndicator} internalLink={'/charts'}>{chartPositionIndicator}</Link>
      }

      {recording.show &&
        <Link className={styles.ShowLink} internalLink={paths.show(recording.show)}>{recording.show.title}</Link>
      }
    </div>
  )
}

const Controls = ({recording, location}) =>
  <div className={styles.Controls}>
    <FavouriteToggle item={itemize(recording, genericItemTypes.recording)} />
    <ToggleAudioVideo recording={recording} location={location} />
    <Share />
  </div>

export default RecordingInfo

// @flow
import moment from 'moment'

import type {RecordingType, GenericItemType} from './types'
import {genericItemTypes} from '../common/constants'
import {recordingHasAudio, recordingHasMedia, recordingHasNoMedia} from './audioVideo'

export const hasNoYoutubePublishDateAndNoMedia = (recording: RecordingType) =>
  !recording.youtube_publish_date && recordingHasNoMedia(recording)

export const hasFutureYoutubePublishDate = (recording: RecordingType) =>
  recording.youtube_publish_date && moment().isBefore(recording.youtube_publish_date)

export const publishedInPastWeek = (recording: RecordingType) =>
  recording.youtube_publish_date &&
  moment(recording.youtube_publish_date).isBetween(moment().subtract(7, 'days'), moment(), undefined, '[]')

export const publishedInPastHour = (recording: RecordingType) =>
  recording.youtube_publish_date &&
  moment(recording.youtube_publish_date).isBetween(moment().subtract(1, 'hour'), moment(), undefined, '[]')

export const recordingsArePlayable = (recordings: Array<RecordingType>) =>
  recordings && !recordings.every(hasNoYoutubePublishDateAndNoMedia)

export const playlistRecordingsArePlayable = (items: Array<GenericItemType>) =>
  items &&
  items.find(item => item.type === genericItemTypes.recording) &&
  !items.every(item => item.recording && hasNoYoutubePublishDateAndNoMedia(item.recording))

export const getFirstPlayableRecording = (recordings: Array<RecordingType>, isAudio: boolean) => {
  if (isAudio) return recordings && recordings.find(recordingHasAudio)
  return recordings && recordings.find(recordingHasMedia)
}
